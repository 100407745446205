import './App.css';
import resume from './images/resume.png';

function App() {
  return (
    <div className="App">
      <img src={resume} alt="resume" />
    </div>
  );
}

export default App;
